module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/demo/src/layouts/index.js"},
    },{
      plugin: require('../../packages/gatsby-theme-newrelic/gatsby-browser.js'),
      options: {"plugins":[],"sitemap":false,"robots":{"env":{"staging":{"host":"https://docs-dev.newrelic.com","policy":[{"userAgent":"*","disallow":["/"]}]},"development":{"policy":[{"userAgent":"*","disallow":["/"]}]},"production":{"policy":[{"userAgent":"*","allow":"/"}]}}},"i18n":{"translationsPath":"/opt/build/repo/demo/src/i18n/translations","additionalLocales":["jp","kr","pt","es","fr"]},"layout":{"component":"/opt/build/repo/demo/src/layouts/index.js","contentPadding":"1.5rem","maxWidth":"1600px","sidebarWidth":"340px","mobileBreakpoint":"760px"},"newrelic":{"config":{"instrumentationType":"proAndSPA","accountId":"10956800","trustKey":"1","agentID":"35094665","licenseKey":"NRJS-649173eb1a7b28cd6ab","applicationID":"35094665","beacon":"staging-bam.nr-data.net","errorBeacon":"staging-bam.nr-data.net","settings":{"session_replay":{"enabled":true,"block_selector":"","mask_text_selector":"*","sampling_rate":100,"error_sampling_rate":100,"mask_all_inputs":true,"collect_fonts":true,"inline_images":false,"inline_stylesheet":true,"mask_input_options":{}},"distributed_tracing":{"enabled":true},"privacy":{"cookies_enabled":true},"ajax":{"deny_list":["staging-bam-cell.nr-data.net"]}}}},"segment":{"segmentWriteKey":"n9T9St8geATEFC1tmc0XH7XzEsOSVZCK","section":"theme_demo","platform":"docs_pages"},"splitio":{"features":{"deven_signupbutton_text":{"treatment":"start_now","config":"{ \"text\": \"Start now\" }"}},"core":{"authorizationKey":"localhost"},"debug":false},"signup":{"environment":"staging","signupUrl":"https://signup-receiver.staging-service.newrelic.com","reCaptchaToken":"6LdMFd8UAAAAAApWFzm8YCyuGCUfg57U1WvqVYqC"},"feedback":{"environment":"staging","reCaptchaToken":"6Lfn8wUiAAAAANBY-ZtKg4V9b4rdGZtJuAng62jo"},"shouldUpdateScroll":{"routes":["/"]},"newRelicRequestingServicesHeader":"gatsby-theme-newrelic-demo"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1200,"linkImagesToOriginal":false,"wrapperStyle":"margin-bottom: var(--paragraph-spacing);"}},{"resolve":"gatsby-remark-autolink-headers","options":{"className":"header-anchor","icon":"<svg xmlns=\"http://www.w3.org/2000/svg\" focusable=\"false\" width=\"1rem\" height=\"1rem\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3\"></path><line x1=\"8\" y1=\"12\" x2=\"16\" y2=\"12\"></line></svg>"}}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo/demo","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
